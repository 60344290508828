/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Suspense, lazy } from "react";
import ReactGA from "react-ga";
import "./App.scss";
import {
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavItem,
  NavLink,
  Footer,
  Container,
  NavbarToggler,
  Collapse,
  MDBRow,
  MDBCol,
  MDBIcon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "mdbreact";
import { Route, withRouter } from "react-router-dom";
import _ from "lodash";
// import NavButtonSet from './components/NavButtonSet/NavbuttonSet';
import { ReactSlackChat } from "react-slack-chat";
import ContactSvc from "./services/ContactSvc";
import Contact from "./components/Contact";
import ContactModal from "./components/ContactModal";
import PortfolioPage from "./components/PortfolioPage"
import DenfitPage from "./components/DenfitPage";
import PitchnPage from "./components/PitchnPage";
import SusansSamosasPage from "./components/SusansSamosasPage";
import SocialPingPage from "./components/SocialPingPage";
// import Products from './components/Products';
import r2c_logo from "./assets/img/r2c_logo.webp";
import r2c_logo_png from "./assets/img/png/r2c_logo.png";
import r2cloud_logo from "./assets/img/r2cloud_logo.webp";
import r2cloud_logo_png from "./assets/img/png/r2cloud_logo.png";
import loading_spinner from "./assets/img/R2Logo.png";
const Home = lazy(() => import("./components/Home"));
const Services = lazy(() => import("./components/Services"));
const Approach = lazy(() => import("./components/ApproachPage"));
const AboutUs = lazy(() => import("./components/AboutUs"));
const Startup = lazy(() => import("./components/startup/Startup"));
const ProductDevelopment = lazy(() =>
  import("./components/ProductDevelopment")
);
const Devops = lazy(() => import("./components/Devops"));
const DataServices = lazy(() => import("./components/DataServices"));

const routes = [
  { id: 1, url: "/", text: "", navbar: "dark", component: Home },
  {
    id: 2,
    url: "/Startup",
    text: "Startup",
    navbar: "dark",
    component: Startup
  },
  {
    id: 3,
    url: "/Services",
    text: "Services",
    navbar: "dark",
    component: Services
  },
  {
    id: 4,
    url: "/Approach",
    text: "Approach",
    navbar: "dark",
    component: Approach
  },
  {
    id: 5,
    url: "/About-Us",
    text: "About Us",
    navbar: "dark",
    component: AboutUs
  },
  {
    id: 6,
    url: "/product-development",
    text: "Product Development",
    navbar: "dark",
    component: ProductDevelopment
  },
  {
    id: 7,
    url: "/data-services",
    text: "Data Services",
    navbar: "dark",
    component: DataServices
  },
  {
    id: 8,
    url: "/devops",
    text: "DevOps",
    navbar: "dark",
    component: Devops
  },
  {
    id: 9,
    url: "blog.r2c.io",
    external: true,
    text: 'Articles',
    navbar: 'dark'
  },
  { 
    id: 10,
    url: "/portfolio",
    text: "Portfolio",
    navbar: "dark",
    component: PortfolioPage
  },
  { 
    id: 11,
    url: "/denver-fitness-week",
    text: "Denver Fitness Week",
    navbar: "dark",
    component: DenfitPage
  },
  { 
    id: 12,
    url: "/pitchn",
    text: "PitchN",
    navbar: "dark",
    component: PitchnPage
  },
  { 
    id: 13,
    url: "/susans-samosas",
    text: "Susans Samosas",
    navbar: "dark",
    component: SusansSamosasPage
  },{ 
    id: 14,
    url: "/social-ping",
    text: "Social Ping",
    navbar: "dark",
    component: SocialPingPage
  }
];

const navRoutes = [
  { id: 1, url: "/", text: "", navbar: "dark", component: Home },
  {
    id: 2,
    url: "/Startup",
    text: "Startup",
    navbar: "dark",
    component: Startup
  },
  {
    id: 3,
    url: "/Services",
    text: "Services",
    navbar: "dark",
    component: Services,
    routes: [
      {
        id: 4,
        url: "/Services",
        text: "IT Services"
      },
      {
        id: 5,
        url: "/product-development",
        text: "Product Development"
      },
      {
        id: 6,
        url: "/data-services",
        text: "Data Services"
      },
      {
        id: 7,
        url: "/devops",
        text: "DevOps"
      }
    ]
  },
  {
    id: 7,
    url: "/About-Us",
    text: "About Us",
    navbar: "dark",
    component: AboutUs
  },
  {
    id: 8,
    url: "#contact-container",
    text: "Contact",
    navbar: "dark",
    component: Contact
  },
  // {
  //   id: 10,
  //   url: "/portfolio",
  //   text: "Portfolio",
  //   navbar: "dark",
  //   component: PortfolioPage
  // }
  // {
  //   id: 9,
  //   url: 'http://blog.r2c.io',
  //   external: true,
  //   text: 'Articles',
  //   navbar: 'dark'
  // }
];

function debounce(fn, ms) {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.call(...args, null), ms);
  };
}

class App extends Component {
  state = {
    collapseID: "",
    collapse1: false,
    userIP: "",
    name: "",
    email: "",
    subject: "",
    message: "",
    token: null,
    showScrollToTop: false,
    modal: false,
    showNumbers: false
  };

  constructor() {
    super();
    this.slackChatRef = React.createRef();
  }

  async componentDidMount() {
    const gaId =
      process.env.REACT_APP_COMPANY &&
      process.env.REACT_APP_COMPANY === "r2cloud"
        ? "UA-171247536-1"
        : "UA-158960432-1";
    ReactGA.initialize(gaId);
    this.trackGAPageViews();
    const userIP = await ContactSvc.getIP();
    this.setState({ userIP });
    window.addEventListener("scroll", debounce(this.handleScroll, 100));
    document.querySelector("h2[class^=ReactSlackChat__subText]").innerText =
      "Ask us anything...and we will do our best to answer";
  }

  componentWillMount() {
    this.unlistenToUrlChanges = this.props.history.listen(location => {
      this.trackGAPageViews();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll");
    this.unlistenToUrlChanges();
  }

  trackGAPageViews = () => {
    const { location } = this.props;
    ReactGA.pageview(location.pathname + location.search);
  };

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  };

  getNavBarColor(path) {
    let color = _.find(routes, { url: path }).navbar;

    return JSON.parse('{"' + color + '":true}');
  }

  isActive(url, path) {
    if (path !== "/") return url === path ? "active" : "";
  }

  getLogo(path) {
    if (
      process.env.REACT_APP_COMPANY &&
      process.env.REACT_APP_COMPANY === "r2cloud"
    )
      return { img: r2cloud_logo, altImg: r2cloud_logo_png };
    else return { img: r2c_logo, altImg: r2c_logo_png };
  }

  handleScroll = e => {
    // console.log(window.scrollY);
    if (window.scrollY > 700) {
      this.setState({ showScrollToTop: true });
    } else {
      this.setState({ showScrollToTop: false });
    }
  };
  toggleContactModal = () => {
    this.setState(prevState => {
      return { modal: !prevState.modal };
    });
  };

  toggleSlackChat = e => {
    let el = this.slackChatRef;
    el.current.openChatBox(e);
    if (this.state.modal) {
      this.toggleContactModal();
    }
  };

  toggleAppointmentWindow = () => {
    let el = document.getElementsByClassName("calendly-badge-content");
    el[0].click();
    if (this.state.modal) {
      this.toggleContactModal();
    }
  };

  scrollToContactForm = () => {
    window.scrollTo({
      top: document.getElementById("contact-container").offsetTop - 90,
      behavior: "smooth"
    });
    if (this.state.modal) {
      this.toggleContactModal();
    }
  };

  showPhoneNumberToggle = () => {
    this.setState(prevState => {
      return { showNumbers: !prevState.showNumbers };
    });
  };

  render() {
    const { history } = this.props;
    const { pathname } = this.props.location;
    const { showScrollToTop } = this.state;

    return (
      <div
        className="App"
        style={{ overflowX: "hidden" }}
        onScroll={e => console.log("scroll")}
      >
        <Navbar
          color="transparent"
          {...this.getNavBarColor(pathname)}
          expand="md"
        >
          <NavbarToggler
            left
            onClick={() => this.toggleSingleCollapse("basicCollapse")}
          />
          <NavbarBrand>
            <NavLink to="/">
              <picture>
                <source srcSet={this.getLogo(pathname).img} type="image" />
                <source srcSet={this.getLogo(pathname).altImg} type="image" />

                <img
                  className={
                    process.env.REACT_APP_COMPANY &&
                    process.env.REACT_APP_COMPANY === "r2cloud"
                      ? "logo_r2cloud"
                      : "logo"
                  }
                  src={this.getLogo(pathname).altImg}
                  alt="R2C Logo"
                />
              </picture>
            </NavLink>
          </NavbarBrand>
          <Collapse id="basicCollapse" isOpen={this.state.basicCollapse}>
            <ul className="ham-menu-list">
              {navRoutes.map(route => {
                if (route.text === "Services") {
                  return (
                    <div key={`nav${route.id}`}>
                      <NavItem
                        onClick={() =>
                          this.toggleSingleCollapse("navItemCollapse")
                        }
                        className="services-nav-item d-flex justify-content-between align-items-center"
                      >
                        <a className="nav-link">{route.text}</a>
                        <MDBIcon
                          icon={
                            this.state.navItemCollapse
                              ? "caret-up"
                              : "caret-down"
                          }
                          style={{ color: "#ffffff", marginRight: " 10px" }}
                        />
                      </NavItem>
                      <Collapse
                        key={`collapsenav${route.id}`}
                        id="navItemCollapse"
                        isOpen={this.state.navItemCollapse}
                      >
                        {route.routes.map(route => (
                          <NavItem
                            className="nested-nav-item"
                            onClick={() =>
                              this.toggleSingleCollapse("basicCollapse")
                            }
                            key={route.id}
                          >
                            <NavLink to={route.url}>{route.text}</NavLink>
                          </NavItem>
                        ))}
                      </Collapse>
                    </div>
                  );
                } else if (route.text === "Contact") {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={`navitem${route.id}`}
                      onClick={() => {
                        this.scrollToContactForm();
                        this.toggleSingleCollapse("basicCollapse");
                      }}
                    >
                      <NavLink to={route.url}>{route.text}</NavLink>
                    </NavItem>
                  );
                } else if (route.external) {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={`navitem2${route.id}`}
                      onClick={() => this.toggleSingleCollapse("basicCollapse")}
                    >
                      <NavLink to={{ pathname: route.url }} target="_blank">
                        {route.text}
                      </NavLink>
                    </NavItem>
                  );
                }
                return (
                  <NavItem
                    className={this.isActive(route.url, pathname)}
                    key={`navitem2${route.id}`}
                    onClick={() => this.toggleSingleCollapse("basicCollapse")}
                  >
                    <NavLink to={route.url}>{route.text}</NavLink>
                  </NavItem>
                );
              })}
            </ul>
          </Collapse>
          <div className="spacer"></div>
          <Container className="nav-links-container">
            <NavbarNav right className="stylish-text">
              {navRoutes.map(route => {
                if (route.text === "Services") {
                  return (
                    <NavItem key={`cont${route.id}`}>
                      <Dropdown>
                        <DropdownToggle nav caret>
                          <div className="d-none d-md-inline">{route.text}</div>
                        </DropdownToggle>
                        <DropdownMenu right>
                          {route.routes.map(route => (
                            <DropdownItem
                              key={route.id}
                              onClick={() => history.push(route.url)}
                            >
                              {route.text}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                  );
                } else if (route.text === "Contact") {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={route.id}
                      onClick={() => this.scrollToContactForm()}
                    >
                      <NavLink to={route.url}>{route.text}</NavLink>
                    </NavItem>
                  );
                } else if (route.external) {
                  return (
                    <NavItem
                      className={this.isActive(route.url, pathname)}
                      key={`navitem2${route.id}`}
                      onClick={() => this.toggleSingleCollapse("basicCollapse")}
                    >
                      <NavLink to={{ pathname: route.url }} target="_blank">
                        {route.text}
                      </NavLink>
                    </NavItem>
                  );
                }
                return (
                  <NavItem
                    className={this.isActive(route.url, pathname)}
                    key={route.id}
                  >
                    <NavLink to={route.url}>{route.text}</NavLink>
                  </NavItem>
                );
              })}
            </NavbarNav>
          </Container>
        </Navbar>
        <Suspense fallback={<div className="loading-spinner animated bounce infinite">
            <img src={loading_spinner} alt="Loading..."/>
            </div>}>
          {routes.map(route => {
            return (
              <Route
                key={route.id}
                exact
                path={route.url}
                render={props => (
                  <route.component
                    {...props}
                    toggleContactModal={this.toggleContactModal}
                    toggleSlackChat={this.toggleSlackChat}
                    toggleAppointmentWindow={this.toggleAppointmentWindow}
                    scrollToContactForm={this.scrollToContactForm}
                  />
                )}
              />
            );
          })}
        </Suspense>
        <div
          className={`scrollToTop-btn ${showScrollToTop ? "show" : ""}`}
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            })
          }
        >
          <span></span>
        </div>
        {/* <Route exact path="/denver-fitness-week">
            <DenfitPage />
        </Route>
        <Route exact path="/pitchn">
            <PitchnPage />
        </Route>
        <Route exact path="/susans-samosas">
            <SusansSamosasPage />
        </Route>
        <Route exact path="/social-ping">
            <SocialPingPage />
        </Route> */}
        {this.state.userIP ? (
          <div>
            <ReactSlackChat
              channels={[
                {
                  name: "talk2us",
                  icon: "https://image.flaticon.com/icons/svg/224/224597.svg"
                }
              ]}
              ref={this.slackChatRef}
              closeChatButton={true}
              helpText={"Slack Us"}
              subText={"Ask us anything...and we will do our best to answer"}
              botName={"r2b_" + this.state.userIP}
              singleUserMode={true}
              userImage={"https://robohash.org/" + this.state.userIP}
              apiToken="eG94Yi01ODUxNzY5NzU5NjktNzAwMzAwMDQwMjU3LTNEYVpTVGY4YTJzSVMwTGo2MThrUDRQcg"
            />
          </div>
        ) : (
          <span></span>
        )}
        <Contact />
        <Footer className="font-small">
          <div className="footer-copyright">
            <Container>
              <MDBRow>
                <MDBCol style={{ paddingRight: 0 }}>
                  <Container style={{ paddingRight: 0 }}>
                    <a
                      className="social"
                      href={
                        process.env.REACT_APP_COMPANY &&
                        process.env.REACT_APP_COMPANY === "r2cloud"
                          ? "https://www.linkedin.com/company/r2cloud-solutions"
                          : "https://www.linkedin.com/company/r2consultingllc"
                      }
                      target="_blank"
                      title="Facebook"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon icon="linkedin" size="2x" />
                    </a>
                    <a
                      className="social"
                      href={
                        process.env.REACT_APP_COMPANY &&
                        process.env.REACT_APP_COMPANY === "r2cloud"
                          ? "https://www.facebook.com/r2cloud"
                          : "https://www.facebook.com/r2.consult"
                      }
                      target="_blank"
                      title="Facebook"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon icon="facebook" size="2x" />
                    </a>
                    <a
                      className="social"
                      href={
                        process.env.REACT_APP_COMPANY &&
                        process.env.REACT_APP_COMPANY === "r2cloud"
                          ? "https://twitter.com/r2cloud"
                          : "https://twitter.com/R2_Innov8"
                      }
                      target="_blank"
                      title="Facebook"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon icon="twitter" size="2x" />
                    </a>
                  </Container>
                </MDBCol>
                <MDBCol className="text-right">
                  &copy; {new Date().getFullYear()}
                  <a href="/">
                    {" "}
                    {process.env.REACT_APP_COMPANY &&
                    process.env.REACT_APP_COMPANY === "r2cloud"
                      ? "R2Cloud Solutions Private Limited. All Rights Reserved. "
                      : "R2 Consulting LLC. All Rights Reserved.  "}{" "}
                  </a>
                </MDBCol>
              </MDBRow>
            </Container>
          </div>
        </Footer>
        <ContactModal
          modal={this.state.modal}
          showNumbers={this.state.showNumbers}
          toggleContactModal={this.toggleContactModal}
          toggleSlackChat={this.toggleSlackChat}
          scrollToContactForm={this.scrollToContactForm}
          toggleAppointmentWindow={this.toggleAppointmentWindow}
          showPhoneNumberToggle={this.showPhoneNumberToggle}
        />
      </div>
    );
  }
}

export default withRouter(App);
