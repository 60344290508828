import React from "react";
import "../common/project-page-style.scss";
import "./style.scss";

const PitchnPage = () => (
	<div className="app-page">
		<div className="app-top pitchn-top">
			<div className="app-detail">
				<div className="app-title">PitchN; The Covid-19 Help App</div>
				<div className="app-description">
					R2 developed a solution born in response to Covid-19 that streamlines
					the connection of volunteers and help seekers for peer-to-peer aid at
					the community level to keep the most vulnerable populations safe from
					possible exposure.
				</div>
                <div className="small-divider" ></div>
				<div className="app-links">
					<p>Visit PitchN on <a href="https://www.pitchnapp.com" className="app-link">www.pitchnapp.com</a> </p>
                    <div>
						<img
							className="social-media-icons"
							src="./img/insta-btn.png"
							alt=""
						/>
						<img className="social-media-icons" src="./img/fb-btn.png" alt="" />
						<img
							className="social-media-icons"
							src="./img/twitter-btn.png"
							alt=""
						/>
						<img
							className="social-media-icons"
							src="./img/linked-in-btn.png"
							alt=""
						/>
					</div>
					<p style={{marginTop: '20px'}}>Download app from</p>
					<div>
						<img src="./img/get-it-gplay.png" style={{marginRight: '5px'}} alt="" />
						<img src="./img/get-it-apple.png" alt="" />
					</div>
				</div>
			</div>
			<div className="app-image pitchn-phone">
				<img alt="" src="./img/pitchn.png" />
			</div>
			<div className="app-stack">
				<p>
					<strong>Type:</strong> Native App
				</p>
				<p>
					<strong>Category:</strong> Lifestyle/Service Industry
				</p>
				<div className="small-divider"></div>
				<div className="tech-libraries">
					<div className="tech-stack">
						<p>
							<strong>Tech Stack</strong>
						</p>
						<div className="stack-items">
							<div>
								<img src="./img/aws-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/nodejs-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/ionic-logo.png" alt="" />
							</div>
						</div>
					</div>
					<div className="design-stack">
						<p>
							<strong>Design Stack</strong>
						</p>
						<div>
							<img src="./img/adobe-logo.png" alt="" />
						</div>
					</div>
					<div className="integrations">
                        <div className="small-divider" style={{margin: '0 0 20px 0'}}></div>
						<p>
							<strong>Integrations</strong>
						</p>
						<div className="stack-items">
							<div>
								<img src="./img/kroger-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/paypal-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/walmart-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/venmo-logo.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="marketing">
			<div className="marketing-posters">
				<div className="poster-images">
					<div>
						<img src="./img/pitchn-poster1.png" alt="" />
					</div>
					<div>
						<img src="./img/pitchn-poster2.png" alt="" />
					</div>
					<div>
						<img src="./img/pitchn-poster3.png" alt="" />
					</div>
				</div>
				<div className="marketing-description">
					<div className="marketing-label">Marketing Design</div>
					<div className="marketing-title">Social Media Posters</div>
					<div className="marketing-content">
						Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
						nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
						volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
						nostrud exerci tation ullamcorper suscipit lobortis
					</div>
				</div>
			</div>
		</div>

		<div className="marketing">
			<div className="marketing-posters">
				<div className="marketing-description">
					<div className="marketing-label">Marketing Design</div>
					<div className="marketing-title">Promotional Videos</div>
					<div className="marketing-content">
						Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
						nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
						volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
						nostrud exerci tation ullamcorper suscipit lobortis
					</div>
				</div>
				<div className="video-images">
					<div>
						<img src="./img/pitchn-video1.png" alt="" />
					</div>
					<div>
						<img src="./img/pitchn-video2.png" alt="" />
					</div>
					<div>
						<img src="./img/pitchn-video2.png" alt="" />
					</div>
				</div>
			</div>
		</div>

		<div className="pitchn-screens">
			<div>
				<img className="phone-screen" src="./img/pitchn-screen1.png" alt="" />
				<img className="screen-dots-1" src="./img/dots.png" alt="" />
			</div>
			<div>
				<img className="phone-screen" src="./img/pitchn-screen2.png" alt="" />
				<img className="screen-dots-2" src="./img/dots.png" alt="" />
			</div>
			<div>
				<img className="phone-screen" src="./img/pitchn-screen3.png" alt="" />
				<img className="screen-dots-3" src="./img/dots.png" alt="" />
			</div>
		</div>

		<div className="app-showcase">
			<div className="showcase-item">
				<div className="showcase-image">
					<img className="img-mod-1" src="./img/pitchn2.png" alt="" />
				</div>
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">
							Website User Interaction Design
						</div>
						<div className="showcase-label">The I in UI</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
			</div>
			<div className="showcase-item">
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">Website Design</div>
						<div className="showcase-label">UI/UX Design</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
				<div className="showcase-image">
					<img className="img-mod-4" src="./img/pitchn-webpage.png" alt="" />
					{/* <img className="img-mod-4" src="./img/pitchn-webpage2.png" alt="" />
                    <img className="img-mod-4" src="./img/pitchn-webpage3.png" alt="" /> */}
				</div>
			</div>
		</div>
	</div>
);

export default PitchnPage;
