import React, { Component } from 'react'
import {
    MDBContainer,
    MDBRow,
    MDBCol,
} from "mdbreact";
import ContactForm from './ContactForm';
import Globe from 'globe.gl';
import countries from '../datasets/locations.json';

export default class Contact extends Component {
    convertRemToPixels(rem) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    componentDidMount() {
        const world = Globe();
        const height = this.convertRemToPixels(25);
        const width = window.innerWidth > 800 ? (window.innerWidth / 3) : window.innerWidth;

        world(document.getElementById('earth'))
            .globeImageUrl("/img/earth-dark.jpg")
            .hexPolygonsData(countries.features)
            .hexPolygonResolution(3)
            .hexPolygonMargin(0.3)
            .hexPolygonColor(() => `#9681af`)
            .width(width)
            .height(height)
            .backgroundColor('#ffffff');
        
        world.onHexPolygonHover((poly) => {
            if (poly) {
                world.controls().autoRotateSpeed = 0;
            }
            else {
                world.controls().autoRotateSpeed = 0.1;
            }
        })
        world.pointOfView({ lat: 39.986758, lng: -105.104963 })
        world.controls().autoRotate = true;
        world.controls().autoRotateSpeed = 0.2;
        world.controls().enableZoom = false;
    }

    render() {
        return (
            <div id="contact-container" style={{paddingTop: '20px'}}>
                {/* <hr style={{ margin: '0rem' }}/> */}
                <MDBContainer className="content-container">
                    <MDBRow>
                        <MDBCol>
                            <h1 className="text-center section-header">Contact Us</h1>
                        </MDBCol>
                    </MDBRow>
                    <br /><br /><br />
                    <MDBRow>
                        <MDBCol className="phone-form-container">
                            <div className="iphonex-container">
                                <img className="home-iphonex" src="/img/iphonex_silhouette.png" alt="Contact us" />
                                <div className="contactform-container">
                                    <ContactForm></ContactForm>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol ref="globeContainer" className="text-center locations-container">
                            <h4 className="section-divider">Our Locations</h4>
                            <MDBRow className="address">
                                <MDBCol>
                                    <div>
                                        <h5>USA</h5>
                                        <b>Strategy</b>
                                        <p>1120 W South Boulder Rd</p>
                                        <p>Suite 101-L</p>
                                        <p>Lafayette, CO 80026</p>
                                        <p>Tel: +1 (720) 699-2223, <br />+1 (855) 277-6220</p>
                                    </div>
                                </MDBCol>
                                <MDBCol className="address-india">
                                    <div>
                                        <h5>India</h5>
                                        <b>Execution</b>
                                        <p>Plot No.15, 9/53 Kaveri Nagar</p>
                                        <p>Moulivakkam, Kundrathur Main Rd</p>
                                        <p>Chennai, TN 600125, India</p>
                                        <p>Tel: +91 (944) 481-1692</p>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBRow>
                                <MDBCol>
                                    <p>Email: <a href="mailto:contact@r2c.io">contact@r2c.io</a></p>
                                </MDBCol>
                            </MDBRow>
                            <div id="earth" style={{display: 'flex', justifyContent: 'center'}} className="d-sm-none d-md-block"></div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }
}
