import React from "react";
import "../common/project-page-style.scss";
import "./style.scss";

const SocialPingPage = () => (
	<div className="app-page">
		<div className="app-top social-ping-top">
			<div className="app-detail">
				<div className="app-title">
					Local Samosa Queen; Meet Susan’s Samosas
				</div>
				<div className="app-description">
					Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
					nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat{" "}
				</div>
                <div className="small-divider small-divider-red"></div>
				<div className="app-links">
					<p>Visit Social Ping on <a href="https://www.socialping.com" className="app-link">www.socialping.com</a></p>
					<p>Follow SocialPing on</p>
                    <div>
						<img
							className="social-media-icons"
							src="./img/insta-btn.png"
							alt=""
						/>
						<img className="social-media-icons" src="./img/fb-btn.png" alt="" />
						<img
							className="social-media-icons"
							src="./img/twitter-btn.png"
							alt=""
						/>
						<img
							className="social-media-icons"
							src="./img/linked-in-btn.png"
							alt=""
						/>
					</div>
					<p style={{marginTop: '20px'}}>Download app from</p>
					<div>
						<img src="./img/get-it-gplay.png" style={{marginRight: '5px'}} alt="" />
						<img src="./img/get-it-apple.png" alt="" />
					</div>
				</div>
			</div>
			<div className="app-image">
				<img alt="" src="./img/social-ping-logo.png" />
			</div>
			<div className="app-stack">
				<p>
					<strong>Type:</strong> Progressive Web App (PWA)
				</p>
				<p>
					<strong>Category:</strong> Lifestyle-Utility Industry
				</p>
				<div className="small-divider small-divider-red"></div>
				<div className="tech-libraries">
					<div className="tech-stack">
						<p>
							<strong>Tech Stack</strong>
						</p>
						<div className="stack-items">
							<div>
								<img src="./img/aws-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/nodejs-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/ionic-logo.png" alt="" />
							</div>
						</div>
					</div>
					<div className="design-stack">
						<p>
							<strong>Design Stack</strong>
						</p>
					</div>
					<div className="integrations">
						<div
							className="small-divider small-divider-red"
							style={{ margin: "0 0 20px 0" }}
						></div>
						<p>
							<strong>Integrations</strong>
						</p>
						<div className="stack-items">
							<div>
								<img src="./img/kroger-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/paypal-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/walmart-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/venmo-logo.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="marketing-section">
			<div className="logo-design">
				<div className="video-title">Branding</div>
				<img src="./img/social-ping-logo.png" alt="" />
				<div className="video-footer">Marketing Videos</div>
			</div>
		</div>

		<div className="app-showcase">
			<div className="showcase-item">
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">Interactive & Visual Design</div>
						<div className="showcase-label">Branding & Marketing Design</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
				<div
					className="showcase-image center-images"
					style={{ margin: 0, marginLeft: "auto" }}
				>
					<img className="img-mod-5" src="./img/social-ping1.png" alt="" />
					<img className="img-mod-5" src="./img/social-ping2.png" alt="" />
				</div>
			</div>
			<div className="social-ping-screens">
				<div>
					<img src="./img/social-ping1.png" alt="" />
				</div>
				<div>
					<img src="./img/social-ping2.png" alt="" />
				</div>
				<div>
					<img src="./img/social-ping3.png" alt="" />
				</div>
			</div>
		</div>
	</div>
);

export default SocialPingPage;
