import React from 'react'
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader
} from "mdbreact";
import FeatherIcon from 'feather-icons-react';

const ContactModal = ({
        modal, 
        showNumbers,
        toggleAppointmentWindow, 
        toggleContactModal, 
        toggleSlackChat, 
        scrollToContactForm, 
        showPhoneNumberToggle
    }) => {

    return (
        <MDBModal centered isOpen={modal} toggle={toggleContactModal} className="contact-modal">
            <MDBModalHeader toggle={toggleContactModal}>Reach out to us!</MDBModalHeader>
            <MDBModalBody>
                <div>
                    <button
                        onClick={(e) => showPhoneNumberToggle() }
                        type="button" className="button-text contact-button">
                        <FeatherIcon style={{float: "left", marginTop: "4px"}} icon="phone" />
                        Call us
                    </button>
                    <div className={ showNumbers ? 'number-hidden' : 'number-open'}>
                        <a href="tel:+18552776220 ">Toll-free: +1 (855) 277-6220</a> <br />
                        <a href="tel:17206992223">United States: +1 (720) 699-2223</a> 
                    </div>
                    <button
                        onClick={(e) => toggleSlackChat(e) }
                        type="button" className="button-text contact-button">
                        <FeatherIcon style={{float: "left", marginTop: "4px"}} icon="message-circle" />
                        Chat with us
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => scrollToContactForm() }
                        type="button" className="button-text contact-button">
                        <FeatherIcon style={{float: "left", marginTop: "4px"}} icon="mail" />
                        Send an email
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => toggleAppointmentWindow() }
                        type="button" className="button-text contact-button">
                        <FeatherIcon style={{float: "left", marginTop: "4px"}} icon="calendar" />
                        Book an appointment
                    </button>
                </div>
            </MDBModalBody>
        </MDBModal>
    )
}

export default ContactModal;