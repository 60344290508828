// import React from 'react';
// import { hydrate, render } from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// import { BrowserRouter } from 'react-router-dom';
// // import 'font-awesome/css/font-awesome.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   hydrate(<BrowserRouter>    
//     <GoogleReCaptchaProvider reCaptchaKey="6Lcjma8UAAAAAK-1Pq5ELVrS-F_AfwxZO5Xrryam">        
    
//     </GoogleReCaptchaProvider>
// </BrowserRouter>, rootElement);
// } else {
//   render(<BrowserRouter>    
//     <GoogleReCaptchaProvider reCaptchaKey="6Lcjma8UAAAAAK-1Pq5ELVrS-F_AfwxZO5Xrryam">        
//     <App />
//     </GoogleReCaptchaProvider>
// </BrowserRouter>, rootElement);
// }

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();


import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import './index.css';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const gtmId = process.env.REACT_APP_COMPANY &&
    process.env.REACT_APP_COMPANY === "r2cloud" ? 'GTM-55JTRTN' : 'GTM-NN4D985';

const tagManagerArgs =  { gtmId }
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <BrowserRouter>
        <GoogleReCaptchaProvider reCaptchaKey="6Lcjma8UAAAAAK-1Pq5ELVrS-F_AfwxZO5Xrryam">
            <ScrollToTop />
            <App />
        </GoogleReCaptchaProvider>
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
