import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { MDBBtn, MDBIcon } from "mdbreact";
import ContactSvc from "../services/ContactSvc";
import React, { Component } from 'react'
import { toast } from "react-toastify";

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {          
          name: "",
          email: "",
          subject: "",
          message: "",
          token: null
        };        
      }

      async sendEmail() {
        if (
          this.state.name &&
          this.state.email &&
          this.state.subject &&
          this.state.message
        ) {
          await ContactSvc.sendEmail(
            this.state.name,
            this.state.email,
            this.state.subject,
            this.state.message
          );
          toast.success("Thanks for your email. We'll be in touch soon!");
        } else {
          toast.info("Please provide all the info so we can respond appropriately");
        }
      }

    render() {
        return (
            <form>
                <p className="h4 text-center mb-4">
                    <img src="/img/R2Logo.png" style={{height: '30px', marginRight: '10px'}} alt="Email Us Logo" />
                    Email us
                </p>
                <label htmlFor="defaultFormContactNameEx" className="grey-text">
                    Your name
              </label>
                <input
                    type="text"
                    id="defaultFormContactNameEx"
                    className="form-control"
                    value={this.state.name}
                    onChange={e => {
                        this.setState({ name: e.target.value });
                    }}
                />
                <br />
                <label htmlFor="defaultFormContactEmailEx" className="grey-text">
                    Your email
              </label>
                <input
                    type="email"
                    id="defaultFormContactEmailEx"
                    className="form-control"
                    value={this.state.emaik}
                    onChange={e => {
                        this.setState({ email: e.target.value });
                    }}
                />
                <br />
                <label
                    htmlFor="defaultFormContactSubjectEx"
                    className="grey-text"
                >
                    Subject
              </label>
                <input
                    type="text"
                    id="defaultFormContactSubjectEx"
                    className="form-control"
                    value={this.state.subject}
                    onChange={e => {
                        this.setState({ subject: e.target.value });
                    }}
                />
                <br />
                <label
                    htmlFor="defaultFormContactMessageEx"
                    className="grey-text"
                >
                    Your message
              </label>
                <textarea
                    type="text"
                    id="defaultFormContactMessageEx"
                    className="form-control"
                    rows="3"
                    value={this.state.message}
                    onChange={e => {
                        this.setState({ message: e.target.value });
                    }}
                />
                <GoogleReCaptcha
                    onVerify={token => {
                        this.setState({ token });
                    }}
                />
                <div className="text-center mt-4">
                    <MDBBtn
                        color="success"
                        type="button"
                        onClick={() => {
                            this.sendEmail();
                        }}
                    >
                        Send
                  <MDBIcon icon="paper-plane-o" className="ml-2" />
                    </MDBBtn>
                </div>
            </form>
        )
    }
}
