import React, { useState, useRef } from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import Scroll from "react-scroll";
// import { useTransition, animated } from "react-spring";
import { Collapse } from "react-collapse";
import "./style.scss";
let Element = Scroll.Element;
let scroller = Scroll.scroller;
let Events = Scroll.Events;
let scrollSpy = Scroll.scrollSpy;

class PortfolioPage extends React.Component {
	state = {
		sectionNumber: 1,
	};

	// componentDidMount() {
	// 	Events.scrollEvent.register("begin", function (to, element) {
	// 		console.log("begin", arguments);
	// 	});

	// 	Events.scrollEvent.register("end", function (to, element) {
	// 		console.log("end", arguments);
	// 	});

	// 	scrollSpy.update();
	// }
	// componentWillUnmount() {
	// 	Events.scrollEvent.remove("begin");
	// 	Events.scrollEvent.remove("end");
	// }

	scrollTo = () => {
		// console.log("sno", this.state.sectionNumber);
		scroller.scrollTo(`section-${this.state.sectionNumber}`, {
			duration: 500,
			delay: 100,
			smooth: "easeOutQuad",
			containerId: "",
		});
	};

	scrollUp = (e) => {
		// console.log("scrolling up", e, this.state.sectionNumber);
		this.setState((prevState) => {
			let sectionNumber =
				prevState.sectionNumber - 1 < 1 ? 1 : prevState.sectionNumber - 1;
			return { sectionNumber };
		});
		this.scrollTo();
	};

	scrollDown = (e) => {
		this.setState((prevState) => {
			let sectionNumber =
				prevState.sectionNumber + 1 < 4 ? prevState.sectionNumber + 1 : 4;
			return { sectionNumber };
		});
		// console.log("scrolling", e, this.state.sectionNumber);
		this.scrollTo();
    };
    
    setOpen = () => {
        this.setState((prevState) => ({
            isOpened: !prevState.isOpened
        }))
    }

	render() {

		return (
			<ReactScrollWheelHandler
				// upHandler={(e) => this.scrollUp(e)}
				// downHandler={(e) => this.scrollDown(e)}
			>
				<div className="home">
					<Element name="section-1">
						<div className="home-top">
							<div className="top-left">
								<div className="project-description">
									We design, develop & deploy meaningful products that are
									custom built for your needs
								</div>
                                <div className="section-label">
                                    The Covid-19 Help App
                                </div>
							</div>
							<div className="top-right">
								<div className="project-image">
									<img src="./img/pitchn.png" />
								</div>
							</div>
						</div>
					</Element>
					<Element name="section-2">
						<div className="home-mid">
							<div className="top-left">
								<div className="project-description">
									We design, develop & deploy meaningful products that are
									custom built for your needs
								</div>
                                {/* <div className="section-label">
                                    Build Apps On-the-Go
                                </div> */}
							</div>
							<div className="top-right">
								<div className="project-image">
									<img src="./img/tangent_one.png" />
								</div>
							</div>
						</div>
					</Element>
					<Element name="section-3">
						<div className="home-bottom">
							<div className="top-left">
								<div className="project-description">
									We design, develop & deploy meaningful products that are
									custom built for your needs
								</div>
							</div>
							<div className="top-right">
								<div className="project-image">
									<img src="./img/denfitweek.png" />
								</div>
							</div>
						</div>
					</Element>
					<div
						className="projects-collapse"
						onClick={this.setOpen}
					>
						Projects
						<Collapse isOpened={this.state.isOpened}>
							<div>ECommerce Industry</div>
                            <div>Lifestyle Industry</div>
                            <div>Management Industry</div>
                            <div>Service Industry</div>
						</Collapse>
					</div>
				</div>
			</ReactScrollWheelHandler>
		);
	}
}

export default PortfolioPage;
