import axios from 'axios';
import publicIp from 'public-ip';
import { v4 as uuidv4 } from 'uuid';

const ContactSvc = {
    getIP: async () => {
        try {
            const ip = await publicIp.v4();

            if(ip)
            {
                console.log(`Hello friend  - ${ip}!`);
            }

            return ip;
        } catch(e) {
            return uuidv4();
        }        
    },
    sendEmail: async (name, email, subject, message, token) => {
        await axios.post('https://lbait8f9x4.execute-api.us-west-2.amazonaws.com/prod/r2c_contact_form_submit', {
            name, email, subject, message, token
        });
    }
};

export default ContactSvc;