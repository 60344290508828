import React from "react";
import "../common/project-page-style.scss";
import "./style.scss";

const SusansSamosasPage = () => (
	<div className="app-page">
		<div className="susans-app-top">
			<div className="app-detail">
				<div className="app-title">
					Local Samosa Queen; Meet Susan’s Samosas
				</div>
				<div className="app-description">
					Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
					nonummy nibh euis mod tincidunt ut laoreet dolore magna aliquam erat{" "}
				</div>
                <div className="small-divider"></div>
				<div className="app-links">
					<p>Visit website on <a href="https://www.susansamosa.com" className="app-link">www.susansamosa.com</a> </p>
					<p>Follow Susan's Samosas on</p>
                    <div>
						<img
							className="social-media-icons"
							src="./img/insta-btn.png"
							alt=""
						/>
						<img className="social-media-icons" src="./img/fb-btn.png" alt="" />
						<img
							className="social-media-icons"
							src="./img/twitter-btn.png"
							alt=""
						/>
						<img
							className="social-media-icons"
							src="./img/linked-in-btn.png"
							alt=""
						/>
					</div>
				</div>
			</div>
			<div className="susans-app-image">
				<div className="app-stack">
					<p>
						<strong>Type:</strong> Progressive Web App (PWA)
					</p>
					<p>
						<strong>Category:</strong> Lifestyle-Utility Industry
					</p>
                    <div className="small-divider"></div>
					<div className="tech-libraries">
						<div className="tech-stack">
							<p>
								<strong>Tech Stack</strong>
							</p>
							<div className="stack-items">
								<div>
									<img src="./img/aws-logo.png" alt="" />
								</div>
								<div>
									<img src="./img/nodejs-logo.png" alt="" />
								</div>
								<div>
									<img src="./img/ionic-logo.png" alt="" />
								</div>
							</div>
						</div>
						<div className="design-stack">
							<p>
								<strong>Design Stack</strong>
							</p>
							<div>
								<img src="./img/adobe-logo.png" alt="" />
							</div>
						</div>
						<div className="integrations">
                            <div className="small-divider" style={{margin: '0 0 20px 0'}}></div>
							<p>
								<strong>Integrations</strong>
							</p>
							<div className="stack-items">
								<div>
									<img src="./img/kroger-logo.png" alt="" />
								</div>
								<div>
									<img src="./img/paypal-logo.png" alt="" />
								</div>
								<div>
									<img src="./img/walmart-logo.png" alt="" />
								</div>
								<div>
									<img src="./img/venmo-logo.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="marketing-section">
			<div className="marketing-video">
				<div className="video-title">Branding</div>
				<img src="./img/susans-samosas2.png" alt="" />
				<div className="video-footer">Marketing Videos</div>
			</div>
		</div>
		<div className="app-showcase">
			<div className="showcase-item">
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">Interactive & Visual Design</div>
						<div className="showcase-label">Branding & Marketing Design</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
				<div className="showcase-image">
					<img className="img-mod-1" src="./img/susans-samosas3.png" alt="" />
				</div>
			</div>
		</div>

		<div className="poster-section">
			<div className="poster1">
				<img src="./img/susans-poster1.png" alt="" />
				<div className="poster-label">Marketing poster</div>
			</div>
			<div className="poster2">
				<img src="./img/susans-poster2.png" alt="" />
				<div className="poster-label">Marketing collaterals</div>
			</div>
		</div>

		<div className="susans-samosas-screens">
			<div>
				<img src="./img/susans-screen1.png" alt="" />
			</div>
			<div>
				<img src="./img/susans-screen2.png" alt="" />
			</div>
			<div>
				<img src="./img/susans-screen3.png" alt="" />
			</div>
		</div>

		<div className="app-features">
			<div className="feature-list">
				<div className="feature-item">
					<div className="feature-image">
						<img alt="" src="./img/susans-feature1.png" />
					</div>
					<div className="feature-label">
						Web App UI
						<div className="">Calendar Appointments</div>
					</div>
				</div>
				<div className="feature-item">
					<div className="feature-label">
						Integrations UI
						<div className="">Address Support</div>
					</div>
					<div className="feature-image">
						<img alt="" src="./img/susans-feature2.png" />
					</div>
				</div>
				<div className="feature-item">
					<div className="feature-image">
						<img alt="" src="./img/susans-feature3.png" />
					</div>
					<div className="feature-label">
						Finance Support
						<div className="">Automatic Tax Updation</div>
					</div>
				</div>
			</div>
		</div>

		<div className="app-showcase">
			<div className="showcase-item">
				<div className="showcase-image">
					<img className="img-mod-2" src="./img/susans-samosas4.png" alt="" />
					<img
						className="img-mod-2 img-mod-3"
						src="./img/susans-samosas5.png"
						alt=""
					/>
				</div>
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">
							Website User Interaction Design
						</div>
						<div className="showcase-label">The I in UI</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
			</div>
			<div className="showcase-item">
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">Website Design</div>
						<div className="showcase-label">Deployment Process</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
				<div className="showcase-image center-images">
					<img className="img-mod-4" src="./img/susans-samosas6.png" alt="" />
					<img className="img-mod-4" src="./img/susans-samosas7.png" alt="" />
				</div>
			</div>
		</div>
	</div>
);

export default SusansSamosasPage;
