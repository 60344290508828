import React from "react";
import "../common/project-page-style.scss";
import "./styles.scss";

const DenfitPage = () => (
	<div className="app-page">
		<div className="app-top denfit-top">
			<div className="app-detail">
				<div className="app-title">
					Denver Fitness Week; The Digital Punch Card
				</div>
				<div className="app-description">
					R2 developed a progressive web application (PWA) that allowed our
					client to digitally transform a fitness company and take the COVID-19
					challenge head-on, whether their customers were taking classes online
					or at the studios.
				</div>
                <div className="small-divider"></div>
				<div className="app-links">
					<p>Visit website on <a href="https://www.denverﬁtness.com" className="app-link">www.denverﬁtness.com</a></p>
					<p>Follow Denver Fitness Week on</p>
					<div>
						<img
							className="social-media-icons"
							src="./img/insta-btn.png"
							alt=""
						/>
						<img className="social-media-icons" src="./img/fb-btn.png" alt="" />
						<img
							className="social-media-icons"
							src="./img/twitter-btn.png"
							alt=""
						/>
						<img
							className="social-media-icons"
							src="./img/linked-in-btn.png"
							alt=""
						/>
					</div>
					<p style={{marginTop: '20px'}}>Download app from</p>
					<div>
						<img src="./img/get-it-gplay.png" style={{marginRight: '5px'}} alt="" />
						<img src="./img/get-it-apple.png" alt="" />
					</div>
				</div>
			</div>
			<div className="app-image denfit-laptop">
				<img alt="" src="./img/denfitweek2.png" />
			</div>
			<div className="app-stack">
				<p>
					<strong>Type:</strong> Progressive Web App (PWA)
				</p>
				<p>
					<strong>Category:</strong> Lifestyle-Utility Industry
				</p>
                <div className="small-divider"></div>
				<div className="tech-libraries">
					<div className="tech-stack">
						<p>
							<strong>Tech Stack</strong>
						</p>
						<div className="stack-items">
							<div>
								<img src="./img/aws-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/nodejs-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/ionic-logo.png" alt="" />
							</div>
						</div>
					</div>
					<div className="design-stack">
						<p>
							<strong>Design Stack</strong>
						</p>
					</div>
					<div className="integrations">
                        <div className="small-divider" style={{margin: '0 0 20px 0'}}></div>
						<p>
							<strong>Integrations</strong>
						</p>
						<div className="stack-items">
							<div>
								<img src="./img/kroger-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/paypal-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/walmart-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/venmo-logo.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="app-features">
			<div className="feature-list">
				<div className="feature-item">
					<div className="feature-image">
						<img alt="" src="./img/feature-image.png" />
					</div>
					<div className="feature-label">
						Web App UI
						<div className="">Feature No.1</div>
					</div>
				</div>
				<div className="feature-item">
					<div className="feature-label">
						Marketing Design
						<div className="">Feature No.2</div>
					</div>
					<div className="feature-image">
						<img alt="" src="./img/feature-image.png" />
					</div>
				</div>
				<div className="feature-item">
					<div className="feature-image">
						<img alt="" src="./img/feature-image.png" />
					</div>
					<div className="feature-label">
						Marketing Design
						<div className="">Feature No.3</div>
					</div>
				</div>
			</div>
		</div>

		<div className="app-showcase">
			<div className="showcase-item">
				<div className="showcase-image">
					<img src="./img/denfitweek2.png" alt="" />
				</div>
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">
							Website User Interaction Design
						</div>
						<div className="showcase-label">The I in UI</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
			</div>
			<div className="showcase-item">
				<div className="showcase-detail">
					<div className="showcase-detail-content">
						<div className="showcase-title">Website Design</div>
						<div className="showcase-label">UI/UX Design</div>
						<div>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
							nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
							erat volutpat. Ut wisi enim ad minim veniam, ad minim veniam, quis
							nostrud exerci tation ullamcorper suscipit lobortis
						</div>
					</div>
				</div>
				<div className="showcase-image">
					<img
						className="img-mod-1"
						src="./img/denfitweek-screen1.png"
						alt=""
					/>
				</div>
			</div>
			<div className="denfitweek-screens">
				<div>
					<img src="./img/denfitweek-screen2.png" alt="" />
				</div>
				<div>
					<img src="./img/denfitweek-screen3.png" alt="" />
				</div>
				<div>
					<img src="./img/denfitweek-screen4.png" alt="" />
				</div>
				<div>
					<img src="./img/denfitweek-screen5.png" alt="" />
				</div>
			</div>
		</div>
	</div>
);

export default DenfitPage;
